import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Footer from './Footer'
import SEO from './SEO'
import { useSeoProps } from '../hooks'

import '../style/gridlex.scss'
import '../style/all.sass'

const Layout = ({ location, data, children }) => {
  const seoProps = useSeoProps(data)
  return (
    <Fragment>
      <SEO {...seoProps} location={location} />
      <div id="container">
        {children}
        <Footer />
      </div>
    </Fragment>
  )
}

Layout.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout
