import React, { useRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { v4 as uuidv4 } from 'uuid'
import NavContainer from '../components/NavContainer'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import HTMLContent from '../components/HTMLContent'
import PieceMeta from '../components/PieceMeta'
import {
  frontmatterProps,
  fieldsProps,
  siteProps,
  pieceMetaProps,
} from '../proptypes'
import { removeTrailingSlash } from '../utils'

const MusicPostTemplate = ({ title, slug, html, inlineImages, pieceMeta }) => {
  const PostClasses = [
    'content',
    'content-music',
    `music-${slug
      .split('/')
      .filter((i) => i)
      .join('-')}`,
  ]
    .filter((i) => i)
    .join(' ')

  const { compositionDate, media } = pieceMeta

  return (
    <div id="content" className={PostClasses}>
      <NavContainer />
      <article>
        <Hero header={`${title} (${compositionDate})`} ref={useRef()} />
        <div className="main-wrapper">
          <main id="main" className="main" role="main">
            <PieceMeta {...pieceMeta} />
            {!!media && !!media.length && (
              <div className="piece-media">
                {media.map((item) => (
                  <div className="piece-media-item" key={uuidv4()}>
                    <HTMLContent content={item} />
                  </div>
                ))}
              </div>
            )}
            {!!html && (
              <Fragment>
                <hr style={{ margin: '3rem auto' }} />
                <h2>Program Notes</h2>
                <HTMLContent content={html} inlineImages={inlineImages} />
              </Fragment>
            )}
          </main>
        </div>
      </article>
    </div>
  )
}

MusicPostTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  inlineImages: PropTypes.array,
  html: PropTypes.string.isRequired,
  pieceMeta: PropTypes.shape(pieceMetaProps).isRequired,
}

const MusicPost = ({ data }) => {
  const {
    frontmatter: { title, pieceMeta },
    fields: { inlineImages, slug },
    html,
  } = data.markdownRemark
  const { location } = data.site.siteMetadata
  location.slug = removeTrailingSlash(slug)
  const postProps = {
    title,
    slug: location.slug,
    inlineImages,
    html,
    pieceMeta,
  }
  return (
    <Layout location={location} data={data}>
      <MusicPostTemplate {...postProps} />
    </Layout>
  )
}

MusicPost.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.shape({
    site: siteProps.isRequired,
    markdownRemark: PropTypes.shape({
      fields: fieldsProps.isRequired,
      frontmatter: frontmatterProps.isRequired,
      html: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default MusicPost

export const PostQuery = graphql`
  query MusicPostTemplate($id: String!) {
    site {
      ...siteMeta
    }
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
        gitAuthorTime
        gitCreatedTime
        inlineImages {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
      }
      frontmatter {
        title
        ...seoFields
        ...featuredImageFields
        date(formatString: "YYYY-MM-DD")
        template
        primaryCategory
        pieceMeta {
          instrumentation
          duration
          performers
          commissioner
          compositionDate(formatString: "YYYY")
          premiere
          score
          code
          media
        }
      }
      html
    }
  }
`
