import React from 'react'
import { pieceMetaProps } from '../proptypes'
import { instrumentAbbreviation, titleCase } from '../utils'
import './PieceMeta.sass'
import Notes from '../../static/core/notes.svg'
import Code from '../../static/core/code.svg'

const PieceMeta = ({
  instrumentation,
  duration,
  performers,
  commissioner,
  premiere,
  score,
  code,
}) => (
  <div className="piece-meta-holder">
    <section className="piece-meta-section details">
      {!!instrumentation && !!instrumentation.length && (
        <div className="piece-meta-item">
          <strong>Instrumentation:</strong>{' '}
          {instrumentation
            .map((inst) => titleCase(instrumentAbbreviation(inst)))
            .join(', ')}
        </div>
      )}
      {!!duration && (
        <div className="piece-meta-item">
          <strong>Duration:</strong> {duration}
        </div>
      )}
      {!!performers && (
        <div className="piece-meta-item">
          <strong>Performers:</strong> {performers}
        </div>
      )}
      {(!!commissioner || !!premiere) && (
        <div
          style={{
            margin: '1rem 0',
            width: '200px',
          }}
        />
      )}
      {!!commissioner && <div className="piece-meta-item">{commissioner}</div>}
      {!!commissioner && !!premiere && (
        <div
          style={{
            margin: '1rem 0',
            width: '200px',
          }}
        />
      )}
      {!!premiere && <div className="piece-meta-item">{premiere}</div>}
    </section>
    {(!!score || !!code) && (
      <section className="piece-meta-section links">
        {!!score && (
          <a
            className="piece-meta-button"
            href={score}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Notes className="piece-meta-button-icon" />
            <div className="piece-meta-button-text">Score &amp;&nbsp;Parts</div>
          </a>
        )}
        {!!code && (
          <a
            className="piece-meta-button"
            href={code}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Code className="piece-meta-button-icon" />
            <div className="piece-meta-button-text">Source Code</div>
          </a>
        )}
      </section>
    )}
  </div>
)

PieceMeta.propTypes = pieceMetaProps

export default PieceMeta
