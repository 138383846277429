import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import Fonts from './Fonts'
import { useDefaultImage, useStructuredData } from '../hooks'
import { removeTrailingSlash } from '../utils'
import { graphql, useStaticQuery } from 'gatsby'

function useSiteDetails() {
  const query = useStaticQuery(
    graphql`
      query SiteDetailsQuery {
        site {
          siteMetadata {
            twitterHandle
            name
            siteName
            jobTitle
            social {
              link
            }
            sameAs
          }
        }
      }
    `,
  )
  return query.site.siteMetadata
}

const SEO = ({
  title,
  description,
  canonical,
  featuredImage,
  slug,
  date,
  dateModified,
  template,
  location,
}) => {
  const defaultImg = useDefaultImage()
  const {
    twitterHandle,
    name,
    siteName,
    jobTitle,
    social,
    sameAs,
  } = useSiteDetails()

  const schemaImg =
    featuredImage && featuredImage.src && featuredImage.src.childImageSharp
      ? featuredImage
      : defaultImg

  const schemaData = {
    template,
    headline: title,
    description,
    logo: `${location.origin}/core/logo.svg`,
    url: removeTrailingSlash(`${location.origin}${slug}`),
    image: {
      url: `${location.origin}/img/${schemaImg.src.childImageSharp.fluid.originalName}`,
      width: schemaImg.src.childImageSharp.original.width,
      height: schemaImg.src.childImageSharp.original.height,
    },
    pageType:
      template &&
      (template.indexOf('blog') !== -1 || template.indexOf('music') !== -1)
        ? 'article'
        : 'webpage',
    datePublished: date,
    dateModified,
    name,
    jobTitle,
    siteName,
    sameAs: [...sameAs, ...social.map((item) => item.link)],
  }
  const imgParts = schemaData.image.url.split('/')
  schemaData.image.url = location.origin + '/img/' + imgParts.pop()
  const ldjson = useStructuredData(schemaData)
  const titleTag =
    slug === '/'
      ? title
      : title.indexOf('| Aaron Gervais | Brewer, Composer') === -1
      ? title + ' | Aaron Gervais | Brewer, Composer'
      : title
  const robots =
    process.env.GATSBY_CMS_BRANCH === 'master'
      ? 'max-snippet:-1, max-image-preview:large, max-video-preview:-1'
      : 'noindex, nofollow'

  return (
    <Fragment>
      <Fonts />
      <Helmet>
        <html lang="en" />
        <title>{titleTag}</title>
        {!!canonical && <link rel="canonical" href={canonical} />}
        <meta name="robots" content={robots} />
        <meta name="description" content={description} />
        <meta property="og:type" content={schemaData.pageType} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={schemaData.url} />
        <meta property="og:image" content={schemaData.image.url} />
        {schemaData.pageType === 'article' && (
          <meta property="article:author" content="Stella Kim" />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={twitterHandle} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={schemaData.image.url} />
        <script type="application/ld+json">{ldjson}</script>
      </Helmet>
    </Fragment>
  )
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // featuredImage: featuredImgProps,
  slug: PropTypes.string.isRequired,
  canonical: PropTypes.string,
  date: PropTypes.string.isRequired,
  dateModified: PropTypes.string.isRequired,
  template: PropTypes.string.isRequired,
  location: PropTypes.shape({
    origin: PropTypes.string.isRequired,
    hostname: PropTypes.string.isRequired,
    protocol: PropTypes.string.isRequired,
  }).isRequired,
}

export default SEO
